body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (min-width: 480px) {
  .media {width: 500px; }

}
.priv-hed{
  font-size:37px;
  color:#124E6B;
}
.content-priv{
  font-size:150%;
  color:black;
  text-indent: initial;
}
.priv-main{
  color: black;
  font-size: 166%;
}
.li-styl {
  font-size: 24px;
  color: black;
}
  